import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDownIcon, Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

const navigation = [
  // { 
  //   name: 'Solutions', 
  //   href: '#', 
  //   hasDropdown: true,
  //   dropdownItems: [
  //     { name: 'AI Legal Research', href: '/coming-soon' },
  //     { name: 'Smart Contracts', href: '/coming-soons' },
  //     { name: 'Case Prediction', href: '/coming-soon' },
  //   ]
  // },
  { name: 'Pricing', href: '/pricing' },
  { name: 'About', href: '/coming-soon' },
];

export default function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`fixed inset-x-0 top-0 z-50 transition-all duration-300 ${isScrolled ? 'bg-black/40 backdrop-blur-xl' : 'bg-transparent'}`}>
      <nav className="flex items-center justify-between px-4 py-3 max-w-7xl mx-auto">
        <motion.div 
          className="flex-1"
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <a href="/" className="flex items-center">
            <span className="sr-only">Evanda AI</span>
            <span className="text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-teal-400">Evanda AI</span>
          </a>
        </motion.div>

        {/* Desktop Menu */}
        <div className="hidden md:flex md:gap-x-8">
          {navigation.map((item, index) => (
            <div 
              key={item.name} 
              className="relative"
              onMouseEnter={() => setHoveredItem(index)}
              onMouseLeave={() => setHoveredItem(null)}
            >
              <a
                href={item.href}
                className="text-sm font-medium text-white hover:text-blue-400 transition duration-150 ease-in-out flex items-center"
              >
                {item.name}
                {item.hasDropdown && (
                  <ChevronDownIcon className="ml-1 h-4 w-4" aria-hidden="true" />
                )}
              </a>
              <AnimatePresence>
                {item.hasDropdown && hoveredItem === index && (
                  <motion.div
                    initial={{ opacity: 0, y: 5 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 5 }}
                    transition={{ duration: 0.15 }}
                    className="absolute left-0 mt-2 w-48 rounded-md overflow-hidden"
                    style={{
                      background: 'rgba(23, 23, 23, 0.8)',
                      backdropFilter: 'blur(10px)',
                      border: '1px solid rgba(255, 255, 255, 0.1)',
                    }}
                  >
                    {item.dropdownItems.map((dropdownItem) => (
                      <motion.a
                        key={dropdownItem.name}
                        href={dropdownItem.href}
                        className="block px-4 py-2 text-sm text-gray-200 hover:bg-blue-500/20 transition-colors duration-150"
                        transition={{ type: 'spring', stiffness: 500 }}
                      >
                        {dropdownItem.name}
                      </motion.a>
                    ))}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          ))}
        </div>

        <motion.div 
          className="hidden md:flex md:flex-1 md:justify-end"
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <motion.button
            whileHover={{ scale: 1.05, boxShadow: '0 0 15px rgba(66, 153, 225, 0.5)' }}
            whileTap={{ scale: 0.95 }}
            className="px-4 py-2 text-sm font-medium text-white rounded-md focus:outline-none"
            style={{ 
              background: 'linear-gradient(45deg, #3b82f6, #2dd4bf)',
              transition: 'all 0.3s ease'
            }}
            onClick={() => navigate('/chat')}
          >
            Launch AI
          </motion.button>
        </motion.div>

        {/* Mobile menu button */}
        <div className="flex md:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </nav>

      {/* Mobile menu */}
      <AnimatePresence>
        {mobileMenuOpen && (
          <motion.div
            initial={{ opacity: 0, x: "100%" }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: "100%" }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-black/90 backdrop-blur-xl px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
          >
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Evanda AI</span>
                <span className="text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-teal-400">Evanda AI</span>
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-white"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <React.Fragment key={item.name}>
                      <a
                        href={item.href}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-blue-500/20"
                      >
                        {item.name}
                      </a>
                      {item.hasDropdown && (
                        <div className="ml-4 space-y-2">
                          {item.dropdownItems.map((dropdownItem) => (
                            <a
                              key={dropdownItem.name}
                              href={dropdownItem.href}
                              className="-mx-3 block rounded-lg px-3 py-2 text-sm leading-7 text-gray-300 hover:bg-blue-500/20"
                            >
                              {dropdownItem.name}
                            </a>
                          ))}
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
                <div className="py-6">
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="w-full px-4 py-2 text-sm font-medium text-white rounded-md focus:outline-none"
                    style={{ 
                      background: 'linear-gradient(45deg, #3b82f6, #2dd4bf)',
                      transition: 'all 0.3s ease'
                    }}
                    onClick={() => navigate('/chat')}

                  >
                    Launch AI
                  </motion.button>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
}