import React from 'react';
import NotFound from '../pages/NotFound';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }; // Update state so the next render shows the fallback UI
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error occurred:", error, errorInfo); // Log error for debugging
  }

  render() {
    if (this.state.hasError) {
      // Render the fallback UI, here you can use NotFound
      return <NotFound />;
    }

    return this.props.children; // If no error, render the children
  }
}

export default ErrorBoundary;
