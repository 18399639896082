import { motion } from 'framer-motion';

export default function PricingPage() {
  return (
    <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56 text-center">
      {/* Animated Header for Pricing Page */}
      <motion.h1 
        className="text-5xl font-extrabold tracking-tight sm:text-7xl bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-emerald-400"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        Exclusive Early Access
      </motion.h1>
      
      {/* Subtitle - Message */}
      <motion.p 
        className="mt-6 text-lg leading-8 text-gray-300"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.6, duration: 0.8 }}
      >
        Get full premium access <span className='font-bold text-white'>completely free</span> during our beta phase.
      </motion.p>

      {/* Additional Info */}
      <motion.p 
        className="mt-6 text-md leading-6 text-gray-400"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1.0, duration: 0.8 }}
      >
        Join now and be the first to experience all features—pricing will be introduced after the beta phase.
      </motion.p>
    </div>
  );
}
