/*
 * Install the Generative AI SDK
 *
 * $ npm install @google/generative-ai
 */

import {
    GoogleGenerativeAI,
  } from "@google/generative-ai";
  
  const apiKey = process.env.REACT_APP_GEMINI_API_KEY;
  const genAI = new GoogleGenerativeAI(apiKey);
  
  const model = genAI.getGenerativeModel({
    model: "gemini-1.5-flash",
    systemInstruction: "You are a legal information assistant for Evanda AI. You should only respond to legal-related questions (e.g., laws, contracts, lawsuits). If a user asks about anything unrelated (such as math, general knowledge, etc.), politely decline and redirect them to ask a legal question. Do not answer non-legal questions. This AI provides general legal information only and is not a substitute for legal advice. For specific issues, consult our partner attorney at iLead Law (ileadlaw.com)."
  });
  
  const generationConfig = {
    temperature: 1,
    topP: 0.95,
    topK: 64,
    maxOutputTokens: 8192,
    responseMimeType: "text/plain",
  };
  
  async function run(conversationHistory, prompt) {
    try {
      const chatSession = model.startChat({
        generationConfig,
        history: conversationHistory,
        // safetySettings: Adjust safety settings as needed
      });
  
      // console.log('Conversation History:', chatSession.getHistory());
  
      const result = await chatSession.sendMessage(prompt);
  
      const response = result.response;
      const responseText = await response.text();
      // console.log('AI Response:', responseText);
      return responseText;
    } catch (error) {
      console.error('Error in run function:', error);
      throw error;
    }
  }
  
  export default run;