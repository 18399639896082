import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import ErrorBoundary from './components/Error';
import { Spin } from 'antd'; // Ant Design Spinner for loading state
import './App.css';
import { ConfigProvider, theme } from 'antd'; // 引入 ConfigProvider 和 theme
import Pricing from './pages/Pricing';
// Lazy load components for better performance
const Home = lazy(() => import('./pages/Home'));
const ComingSoon = lazy(() => import('./pages/ComingSoon'));
const Main = lazy(() => import('./pages/Chat'));
const NotFound = lazy(() => import('./pages/NotFound'));
// You can add other pages like Solutions, Pricing, Contact here when needed.

function Layout() {
  const location = useLocation(); // Get the current path

  return (
    <>
      {/* Conditionally render Navbar or Sidebar based on the current path */}
      {!location.pathname.includes('/chat') && <Navbar />}
      
      {/* Suspense to show a loading state while components load */}
      <Suspense
        fallback={
          // Centered spinner using Tailwind CSS
          <div className="flex items-center justify-center min-h-screen">
            <Spin size="large" tip="Loading" />
          </div>
        }
      >
        <Routes>
          <Route index element={<Home />} /> {/* Default route */}
          <Route path="pricing" element={<Pricing />} />

          <Route path="coming-soon" element={<ComingSoon />} />
          <Route path="chat" element={<Main />} /> {/* Handle /chat route */}
          <Route path="chat/:conversationId" element={<Main />} />
          <Route path="*" element={<NotFound />} /> {/* Catch-all for 404 */}
        </Routes>
      </Suspense>
    </>
  );
}

function App() {
  return (
    <ConfigProvider
    theme={{
      algorithm: theme.darkAlgorithm, // 启用暗黑主题
      components: {
        Menu: {
          itemBg: 'transparent',
          itemColor: 'rgba(255, 255, 255, 0.65)',
          itemHoverBg: 'rgba(255, 255, 255, 0.08)',
          itemSelectedBg: 'rgba(59, 130, 246, 0.3)',
          itemSelectedColor: '#ffffff',
          groupTitleColor: '#3b82f6',
          // cursor: 'pointer',  // Ensure pointer cursor for all Menu items

        },
      },
      token: {
        colorBgContainer: 'transparent',
        colorText: 'rgba(255, 255, 255, 0.85)',
      },
    }}
  >
    <ErrorBoundary>
      <BrowserRouter>
        <Layout />
      </BrowserRouter>
    </ErrorBoundary>
    </ConfigProvider>
  );
}

export default App;
