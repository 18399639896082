import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56 text-center">
      {/* Animated Header for "Not Found" */}
      <motion.h1 
        className="text-5xl font-extrabold tracking-tight sm:text-7xl bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-emerald-400"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        404 NOT FOUND
      </motion.h1>
      
      {/* "Go to Home" Button */}
      <motion.div 
        className="mt-10"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1, duration: 0.8 }}
      >
        <motion.button
          whileHover={{ scale: 1.05, boxShadow: '0 0 15px rgba(66, 153, 225, 0.5)' }}
          whileTap={{ scale: 0.95 }}
          onClick={() => navigate('/')} // Redirect to home
          className="px-5 py-3 text-lg font-medium text-white bg-blue-500 rounded-lg focus:outline-none hover:bg-blue-600"
        >
          Go to Home
        </motion.button>
      </motion.div>
    </div>
  );
}
