import { createContext, useState, useEffect, useCallback } from 'react';
import run from '../config/gemini';
import { v4 as uuidv4 } from 'uuid';

export const Context = createContext();

const ContextProvider = (props) => {
  const [input, setInput] = useState('');
  const [conversations, setConversations] = useState([]);
  const [currentConversationId, setCurrentConversationId] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resultData, setResultData] = useState('');

  // Retrieve conversations from localStorage on mount
  useEffect(() => {
    const savedConversations = localStorage.getItem('conversations');
    
    if (savedConversations) {
      try {
        const parsedConversations = JSON.parse(savedConversations);
  
        // Filter out conversations missing required fields: id, messages (must be an array), and summary
        const validConversations = parsedConversations.filter(convo => {
          return convo.id && Array.isArray(convo.messages) && convo.summary;
        });
  
        // Only set the valid conversations
        setConversations(validConversations);
        setCurrentConversationId(validConversations.length > 0 ? validConversations[0].id : null);
  
        // Save the filtered conversations back to localStorage
        localStorage.setItem('conversations', JSON.stringify(validConversations));
  
      } catch (error) {
        console.error('Error parsing or validating conversations:', error);
        // Reset to default empty conversations if parsing fails
        setConversations([]);
        setCurrentConversationId(null);
      }
    } else {
      setConversations([]);
      setCurrentConversationId(null);
    }
  }, []);
  
  
  

  // Save conversations to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem('conversations', JSON.stringify(conversations));
  }, [conversations]);


  const cleanConversationHistory = (messages) => {
    return messages.map((message) => ({
      role: message.role,
      parts: message.parts.map((part) => ({ text: part.text })),
    }));
  };
  
  // Handle the bot's response after the user's message is sent
  const onSent = useCallback(async (conversation, conversationId) => {
    setLoading(true);

    try {
      const lastMessage = conversation.messages[conversation.messages.length - 1];

      if (lastMessage.role !== 'user') {
        console.error('No valid user message in conversation, aborting AI response.');
        return;
      }

      const prompt = lastMessage.parts.map((part) => part.text).join('');
      const conversationHistory = cleanConversationHistory(conversation.messages.slice(0, -1));

      // Generate the conversation history, excluding the last message
      // console.log('conversationHistory',conversationHistory,'conversationHistory')
      // console.log('prompt',prompt,'prompt')
      const response = await run(conversationHistory, prompt);
      setResultData(response);

      const aiMessage = {
        id: uuidv4(),
        role: 'model',
        parts: [{ text: response }],
        timestamp: new Date().toISOString(),
      };

      setConversations((prevConversations) => {
        const updatedConversations = [...prevConversations];

        const currentConversation = updatedConversations.find(
          convo => convo.id === conversationId
        );

        if (currentConversation) {
          // console.log('ai message',aiMessage)
          currentConversation.messages.push(aiMessage);
          currentConversation.lastUpdated = new Date().toISOString();
        } else {
          console.error('Conversation not found, aborting AI response.');
        }

        return updatedConversations;
      });
    } catch (error) {
      console.error('Error in onSent:', error);
      setResultData('There was an error processing your request.');
    } finally {
      setLoading(false);
      setIsTyping(false);
    }
  }, []);

  // Start a new conversation
  const startNewConversation = () => {
    
    setCurrentConversationId(null);
    // console.log('Starting a new conversation');
  };

  const contextValue = {
    input,
    setInput,
    conversations,
    setConversations,
    currentConversationId,
    setCurrentConversationId,
    onSent,
    loading,
    resultData,
    startNewConversation,
    setIsTyping,
    isTyping,
  };

  return <Context.Provider value={contextValue}>{props.children}</Context.Provider>;
};

export default ContextProvider;
